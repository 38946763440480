import {VekaletUcretDonemEntity} from "@/entity/VekaletUcretDonemEntity";
import {VekaletUcretOranEntity} from "@/entity/VekaletUcretOranEntity";
import {VekaletOranListResponseProvider} from "@/services/VekaletOranService";
import {VekaletOranListResponseProvider2} from "@/services/VekaletOranService2";

class vekaletDonemListResponse {
    data!: VekaletUcretDonemEntity[]
}

export function VekaletDonemListResponseProvider(depth: number = 3): vekaletDonemListResponse {

    let vekaletOranlar = Array<VekaletUcretOranEntity>();
    let vekaletOranlar2 = Array<VekaletUcretOranEntity>();
    if (--depth > 0) {
        vekaletOranlar = VekaletOranListResponseProvider(depth).data;
        vekaletOranlar2 = VekaletOranListResponseProvider2(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                baslangicTarihi: new Date("1970-01-01"),
                bitisTarihi: new Date("2017-01-02"),
                oranlar: vekaletOranlar,
            },
            {
                id: 2,
                baslangicTarihi: new Date("2017-01-02"),
                bitisTarihi: new Date("2018-01-02"),
                oranlar: vekaletOranlar2,
            },
            {
                id: 3,
                baslangicTarihi: new Date("2018-01-02"),
                bitisTarihi: new Date("2019-01-02"),
                oranlar: vekaletOranlar,
            },
            {
                id: 4,
                baslangicTarihi: new Date("2019-01-02"),
                bitisTarihi: new Date("2020-01-02"),
                oranlar: vekaletOranlar2,
            },
            {
                id: 5,
                baslangicTarihi: new Date("2020-01-02"),
                bitisTarihi: new Date("2021-01-02"),
                oranlar: vekaletOranlar,
            },


        ]
    }
}
