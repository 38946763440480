import {VekaletUcretDonemEntity} from "@/entity/VekaletUcretDonemEntity";
import {VekaletDonemListResponseProvider} from "@/services/VekaletDonemService";
import {VekaletUcretleriTaslakEntity} from "@/entity/VekaletUcretleriTaslakEntity";

export interface VekaletUcretleriTaslakResponse {
    data: Array<VekaletUcretleriTaslakEntity>
}

export function VekaletUcretleriTaslakListProvider(depth: number = 3): VekaletUcretleriTaslakResponse {
    let vekaletDonemler = Array<VekaletUcretDonemEntity>();
    if (--depth > 0) {
        vekaletDonemler = VekaletDonemListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id: 1,
                taslakAdi:"GDZ Vekalet Ucretleri",
                olusturan:"Kaan Yılmaz",
                donemler:vekaletDonemler
            },
        ]
    }
}