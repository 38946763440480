import {VekaletUcretOranEntity} from "@/entity/VekaletUcretOranEntity";
import {VekaletDonemListResponseProvider} from "@/services/VekaletDonemService";
import {VekaletUcretDonemEntity} from "@/entity/VekaletUcretDonemEntity";

class vekaletOranListResponse2 {
    data!: VekaletUcretOranEntity[]
}

export function VekaletOranListResponseProvider2(depth:number=3): vekaletOranListResponse2 {
    let vekaletDonemler = Array<VekaletUcretDonemEntity>();
    if(--depth > 0)
    {
        vekaletDonemler = VekaletDonemListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id:1,
                donem: vekaletDonemler[1],
                muvekkilOran: 35.00,
                borcluOran: 35.00,
                ustSinir: 200.00,
            },
            {
                id:2,
                donem: vekaletDonemler[1],
                muvekkilOran: 33.00,
                borcluOran: 33.00,
                ustSinir: 400.00,
            },
            {
                id:3,
                donem: vekaletDonemler[1],
                muvekkilOran: 32.00,
                borcluOran: 32.00,
                ustSinir: 800.00,
            },
            {
                id:4,
                donem: vekaletDonemler[1],
                muvekkilOran: 30.00,
                borcluOran: 30.00,
                ustSinir: 1600.00,
            },
            {
                id:5,
                donem: vekaletDonemler[1],
                muvekkilOran: 2.00,
                borcluOran: 27.00,
                ustSinir: 3200.00,
            },
            {
                id:6,
                donem: vekaletDonemler[1],
                muvekkilOran: 25.00,
                borcluOran: 25.00,
                ustSinir: 6400.00,
            },
            {
                id:7,
                donem: vekaletDonemler[1],
                muvekkilOran: 24.00,
                borcluOran: 24.00,
                ustSinir: 12800.00,
            },
            {
                id:8,
                donem: vekaletDonemler[1],
                muvekkilOran: 20.50,
                borcluOran: 20.50,
                ustSinir: 25600.00,
            },
            {
                id:9,
                donem: vekaletDonemler[1],
                muvekkilOran: 18.00,
                borcluOran: 18.00,
                ustSinir: 51200.00,
            },
            {
                id:10,
                donem: vekaletDonemler[1],
                muvekkilOran: 16.00,
                borcluOran: 16.00,
                ustSinir: 102400.00,
            },
            {
                id:11,
                donem: vekaletDonemler[1],
                muvekkilOran: 14.00,
                borcluOran: 15.00,
                ustSinir: 204800.00,
            },
            {
                id:12,
                donem: vekaletDonemler[1],
                muvekkilOran: 11.50,
                borcluOran: 11.50,
                ustSinir: 409600.00,
            },
            {
                id:13,
                donem: vekaletDonemler[1],
                muvekkilOran: 8.00,
                borcluOran: 8.00,
                ustSinir: 1000000.00,
            },
            {
                id:14,
                donem: vekaletDonemler[1],
                muvekkilOran: 6.00,
                borcluOran: 6.00,
                ustSinir: 2500000.00,
            },
            {
                id:15,
                donem: vekaletDonemler[1],
                muvekkilOran: 4.50,
                borcluOran: 4.50,
                ustSinir: 8000000.00,
            },
            {
                id:16,
                donem: vekaletDonemler[1],
                muvekkilOran: 2.80,
                borcluOran: 2.80,
                ustSinir: 15000000.00,
            },
            {
                id:17,
                donem: vekaletDonemler[1],
                muvekkilOran: 2.00,
                borcluOran: 2.00,
                ustSinir: 9999999999.00,
            },



        ]
    }
}
