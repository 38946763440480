import {VekaletUcretOranEntity} from "@/entity/VekaletUcretOranEntity";
import {VekaletDonemListResponseProvider} from "@/services/VekaletDonemService";
import {VekaletUcretDonemEntity} from "@/entity/VekaletUcretDonemEntity";

class vekaletOranListResponse {
    data!: VekaletUcretOranEntity[]
}

export function VekaletOranListResponseProvider(depth:number=3): vekaletOranListResponse {
    let vekaletDonemler = Array<VekaletUcretDonemEntity>();
    if(--depth > 0)
    {
        vekaletDonemler = VekaletDonemListResponseProvider(depth).data;
    }
    return {
        data: [
            {
                id:1,
                donem: vekaletDonemler[0],
                muvekkilOran: 17.00,
                borcluOran: 17.00,
                ustSinir: 5000.00,
            },
            {
                id:2,
                donem: vekaletDonemler[0],
                muvekkilOran: 16.00,
                borcluOran: 16.00,
                ustSinir: 10000.00,
            },
            {
                id:3,
                donem: vekaletDonemler[0],
                muvekkilOran: 15.00,
                borcluOran: 15.00,
                ustSinir: 18000.00,
            },
            {
                id:4,
                donem: vekaletDonemler[0],
                muvekkilOran: 14.00,
                borcluOran: 14.00,
                ustSinir: 28000.00,
            },
            {
                id:5,
                donem: vekaletDonemler[0],
                muvekkilOran: 13.00,
                borcluOran: 13.00,
                ustSinir: 40000.00,
            },
            {
                id:6,
                donem: vekaletDonemler[0],
                muvekkilOran: 12.00,
                borcluOran: 12.00,
                ustSinir: 55000.00,
            },
            {
                id:7,
                donem: vekaletDonemler[0],
                muvekkilOran: 11.00,
                borcluOran: 11.00,
                ustSinir: 72000.00,
            },
            {
                id:8,
                donem: vekaletDonemler[0],
                muvekkilOran: 10.50,
                borcluOran: 10.50,
                ustSinir: 100000.00,
            },
            {
                id:9,
                donem: vekaletDonemler[0],
                muvekkilOran: 9.00,
                borcluOran: 9.00,
                ustSinir: 140000.00,
            },
            {
                id:10,
                donem: vekaletDonemler[0],
                muvekkilOran: 8.00,
                borcluOran: 8.00,
                ustSinir: 200000,
            },
            {
                id:11,
                donem: vekaletDonemler[0],
                muvekkilOran: 7.00,
                borcluOran: 7.00,
                ustSinir: 300000.00,
            },
            {
                id:12,
                donem: vekaletDonemler[0],
                muvekkilOran: 6.50,
                borcluOran: 6.50,
                ustSinir: 500000.00,
            },
            {
                id:13,
                donem: vekaletDonemler[0],
                muvekkilOran: 5.00,
                borcluOran: 5.00,
                ustSinir: 1000000.00,
            },
            {
                id:14,
                donem: vekaletDonemler[0],
                muvekkilOran: 4.00,
                borcluOran: 4.00,
                ustSinir: 2500000.00,
            },
            {
                id:15,
                donem: vekaletDonemler[0],
                muvekkilOran: 3.50,
                borcluOran: 3.50,
                ustSinir: 8000000.00,
            },
            {
                id:16,
                donem: vekaletDonemler[0],
                muvekkilOran: 2.11,
                borcluOran: 2.11,
                ustSinir: 15000000.00,
            },
            {
                id:17,
                donem: vekaletDonemler[0],
                muvekkilOran: 1.00,
                borcluOran: 1.00,
                ustSinir: 9999999999.00,
            },

        ]
    }
}
